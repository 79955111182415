































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import ItemDataService from "@/api/itemdata";

@Component({})
export default class EditMuestraModal extends Vue {
  [x: string]: any;
  data: any = {};
  loaded = false;

  @Emit()
  close() {
    return false;
  }
  @Prop(Object) readonly nuezSelected: any;
  @Prop(Object) readonly itemData: any;

  editSample() {
    delete this.data.entry_date;
    ItemDataService.update(this.data).then(() => {
      this.$buefy.notification.open({
        message: "Se ha actualizado la muestra",
        type: "is-success"
      });
      this.$emit("close");
    });
  }
  mounted() {
    this.data = this.itemData;
    this.data.entry_date = this.$date(this.data.entry_date).toDate();
    this.data.sample = true;
    this.loaded = true;
  }
}
