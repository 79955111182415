












































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import CatalogService from "@/api/catalogs";
import ItemService from "@/api/items";

@Component({})
export default class EditModal extends Vue {
  [x: string]: any;
  nuez: any = {};

  medidas: any[] = [];

  @Emit()
  close() {
    return false;
  }
  @Prop(Object) nuezSelected: any;

  get itemGlobal() {
    return this.nuez.item_data.find((n: any) => n.sample == false);
  }

  editNuez() {
    const muestra = this.nuez.item_data.find((n: any) => n.sample == false);
    delete muestra.entry_date;
    const nuez = {
      id: this.nuez.id,
      client_id: this.nuez.client_id,
      entry_date: this.nuez.entry_date,
      in_storage: this.nuez.in_storage,
      price: this.nuez.price,
      stack_type_id: this.nuez.stack_type_id,
      storage_id: this.nuez.storage_id
    };
    const globalItem = this.nuezSelected.item_data.find((n: any) => !n.sample);
    if (globalItem.weight != muestra.weight) {
      this.$buefy.dialog.confirm({
        message: "¿Quieres actualizar el peso de las muestras?",
        confirmText: "Si",
        cancelText: "No",
        type: "is-default",
        onConfirm: () => {
          this.updateItem(nuez, muestra, true);
        },
        onCancel: () => {
          this.updateItem(nuez, muestra, false);
        }
      });
      return;
    }
    this.updateItem(nuez, muestra, false);
  }

  updateItem(nuez: any, muestra: any, calcWeight: boolean) {
    ItemService.update(
      nuez.id,
      {
        item: nuez,
        item_data: muestra,
        calc_weight: calcWeight
      },
      "id, price, stack_type_id, in_storage, client_id, item_data.sample, item_data.weight, item_data.stacks, item_data.humidity, item_data.quality, item_data.entry_date, item_data.id, entry_date, description"
    ).then(response => {
      this.$store.dispatch("ItemModule/setItem", response.data);
      this.$emit("close");
      this.$buefy.notification.open({
        message: "La entrada de nuez se ha actualizado",
        type: "is-success"
      });
    });
  }

  mounted() {
    this.nuez = JSON.parse(JSON.stringify(this.nuezSelected));
    this.nuez.entry_date = this.$date(this.nuez.entry_date).toDate();
    CatalogService.getStackTypes().then(response => {
      this.medidas = response.data;
    });
  }
}
