































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import ItemDataService from "@/api/itemdata";

@Component({})
export default class AddMuestraModal extends Vue {
  data: any = {};
  loaded = false;

  @Emit()
  close() {
    return false;
  }
  @Prop(Object) readonly nuezSelected: any;

  addSample() {
    ItemDataService.create(this.nuezSelected.id, this.data).then(() => {
      this.$buefy.notification.open({
        message: "Se ha agregado la muestra",
        type: "is-success"
      });
      this.$emit("close");
    });
  }
  mounted() {
    this.data = {
      entry_date: new Date(),
      humidity: null,
      quality: null,
      weight: null,
      stacks: null,
      sample: true
    };
    this.loaded = true;
  }
}
