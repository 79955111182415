





























































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import AddModal from "@/components/nueces/AddModal.vue";
import AddMuestraModal from "@/components/nueces/AddMuestraModal.vue";
import CatalogService from "@/api/catalogs";
import ClientService from "@/api/clients";
import EditModal from "@/components/nueces/EditModal.vue";
import ExcelModal from "@/components/excel/ExcelModal.vue";
import NuezCard from "@/components/nueces/NuezCard.vue";
import ItemService from "@/api/items";
import TableMuestra from "@/components/nueces/TableMuestra.vue";
import HelperFuntions from "@/mixins/helperFuntions";
import { mapGetters } from "vuex";
import { Debounce } from "vue-debounce-decorator";

@Component({
  components: {
    AddModal,
    AddMuestraModal,
    TableMuestra,
    EditModal,
    ExcelModal,
    NuezCard
  },
  computed: {
    ...mapGetters("ItemModule", {
      nuecesData: "getItems",
      dashboard: "getDashboard"
    }),
    ...mapGetters("UserModule", {
      storage: "getStorage"
    })
  }
})
export default class Nueces extends Vue {
  filters = {
    producer: "",
    price: false,
    id: "",
    "item_data.humidity": false,
    "item_data.stacks": false,
    "item_data.quality": false,
    "item_data.weight": false
  };
  isLoading = false;
  filtersActive: any = {};
  filtersData = {
    producers: []
  };
  isFilterToggled = false;
  isAddingSample = false;
  isShowingSamples = false;
  isHovering = false;
  isAddingNueces = false;
  nuezSelected: any = {};
  isEditing = false;
  nuecesData!: any[];
  dashboard!: any[];
  storage!: any;
  medidas: any[] = [];
  showPast = false;
  nuecesHistorial: any[] = [];
  nuezInHistorial = false;
  pagination: any = {
    total: 0,
    current_page: 1,
    per_page: 29
  };
  paginationHistorial: any = {
    total: 0,
    current_page: 1,
    per_page: 29
  };
  viewType = true;
  updateKey = 1;
  excelStructure: any = {
    data_structure: {
      "#": ["id"],
      Productor: ["client.name", "client.last_name"],
      Descipcion: ["description"],
      "Fecha entrada": ["entry_date"],
      "Precio por punto": ["price"],
      function_array: {
        array_key: "item_data",
        operation: {
          key: "sample",
          operation: "=",
          comperation: false
        },
        keys: {
          Kg: ["weight"],
          Humedad: ["humidity"],
          "Cantidad de contenedores": ["stacks"],
          "Tipo de contenedor": ["..stack_type.name"],
          Calidad: ["quality"]
        }
      }
    }
  };

  excelRequest() {
    this.isLoading = true;
    this.filtersActive["hidden"] = false;
    return ItemService.exportAllExcel(
      this.storage.id,
      HelperFuntions.ArrayToFilterString([
        "id",
        "price",
        "stack_type_id",
        "in_storage",
        "client_id",
        "item_data.sample",
        "item_data.weight",
        "item_data.stacks",
        "item_data.humidity",
        "item_data.quality",
        "item_data.entry_date",
        "item_data.id",
        "entry_date",
        "storage_id",
        "description"
      ]),
      this.filtersActive,
      this.excelStructure
    );
  }

  itemGlobal(nuezId: number) {
    const nuez = this.nuecesData.find(x => x.id == nuezId);
    return nuez.item_data.find((n: any) => n.sample == false);
  }

  editNuez(nuezId: number): void {
    this.nuezSelected = this.nuecesData.find(x => x.id == nuezId);
    this.isEditing = true;
  }

  hideNuez(nuezId: number): void {
    ItemService.update(nuezId, { item: { hidden: true } }).then(() => {
      const index = this.nuecesData.findIndex(x => x.id == nuezId);
      this.nuecesData.splice(index, 1);
      this.$buefy.notification.open({
        message: "Las nueces han sido ocultadas",
        type: "is-success"
      });
    });
  }

  toggleInStorage(val: any) {
    this.filtersActive["in_storage"] = val;
    if (val === undefined) {
      delete this.filtersActive["in_storage"];
    }
    this.getAllItems();
  }

  deleteNuez(nuezId: number): void {
    this.$buefy.dialog.confirm({
      message:
        "¿Estas seguro de borrar estas nueces? Se borrarán las muestras asociadas a estas nueces",
      cancelText: "Cancelar",
      confirmText: "Si, borrar",
      type: "is-danger",
      onConfirm: () => {
        ItemService.delete(nuezId)
          .then(response => {
            const index = this.nuecesData.findIndex(
              x => x.id == response.data.id
            );
            this.nuecesData.splice(index, 1);
            this.$buefy.notification.open({
              message: "Las nueces han sido eliminadas",
              type: "is-success"
            });
          })
          .catch(() => {
            this.$buefy.notification.open({
              message:
                "Las nueces no se pueden borrar porque contienen muestras con lotes asociados",
              type: "is-warning"
            });
          });
      }
    });
  }

  closeEdit() {
    this.isEditing = false;
    this.updateKey++;
  }

  showSamples(nuezId: number): void {
    this.nuezSelected = this.nuecesData.find(x => x.id == nuezId);
    if (!this.nuezSelected) {
      this.nuezSelected = this.nuecesHistorial.find(x => x.id == nuezId);
      this.nuezInHistorial = true;
    }
    this.isShowingSamples = true;
  }

  addSample(nuezId: number): void {
    this.nuezSelected = this.nuecesData.find(x => x.id == nuezId);
    this.$store.dispatch("ItemModule/setActiveItem", this.nuezSelected);
    this.$router.push({
      name: "NuevaMuestra",
      params: { idNuez: nuezId.toString() }
    });
  }

  setProducerFilter(val: any) {
    this.filtersActive["client_id"] = val.id;
    this.getAllItems();
  }

  @Debounce(500)
  searchProducers(val: string) {
    if (val.length <= 2) {
      delete this.filtersActive["client_id"];
      this.getAllItems();
    }
    ClientService.search(this.storage.id, val).then(response => {
      this.filtersData.producers = response.data.map((x: any) => {
        return {
          id: x.id,
          fullname: x.name + " " + x.last_name
        };
      });
    });
  }

  get routeName() {
    return this.$route.name;
  }

  get formattedDashboard() {
    if (this.isLoading || !this.dashboard)
      return {
        total: {
          stacks: 0,
          weight: 0
        }
      };
    const pricingGroups: any = {
      total: {
        stacks: 0,
        weight: 0
      }
    };
    this.dashboard.forEach((x: any) => {
      if (pricingGroups[`${x.price}`]) {
        pricingGroups[`${x.price}`].stacks += x.stacks;
        pricingGroups[`${x.price}`].weight += x.weight;
      } else {
        pricingGroups[`${x.price}`] = {
          stacks: x.stacks,
          weight: x.weight
        };
      }
      pricingGroups.total.stacks += x.stacks;
      pricingGroups.total.weight += x.weight;
    });
    return Object.entries(pricingGroups).sort();
  }

  getAllItems() {
    this.isLoading = true;
    this.filtersActive["hidden"] = false;
    ItemService.getAll(
      this.storage.id,
      HelperFuntions.ArrayToFilterString([
        "id",
        "price",
        "stack_type_id",
        "client_id",
        "item_data.sample",
        "item_data.weight",
        "item_data.stacks",
        "item_data.humidity",
        "item_data.quality",
        "item_data.entry_date",
        "item_data.id",
        "entry_date",
        "storage_id",
        "description",
        "quantity_avg"
      ]),
      this.filtersActive
    ).then(response => {
      this.isLoading = false;
      this.pagination.total = response.data.total;
      this.pagination.current_page = response.data.current_page;
      this.pagination.per_page = response.data.per_page;
      this.$store.dispatch("ItemModule/setItems", response.data.data);
    });
  }

  loadPast(toggleBtn = false) {
    this.isLoading = true;
    if (toggleBtn) {
      this.showPast = !this.showPast;
    }
    this.filtersActive["per_page"] = this.paginationHistorial.per_page;
    this.filtersActive["hidden"] = true;
    ItemService.getAll(
      this.storage.id,
      HelperFuntions.ArrayToFilterString([
        "id",
        "price",
        "stack_type_id",
        "in_storage",
        "client_id",
        "item_data.sample",
        "item_data.weight",
        "item_data.stacks",
        "item_data.humidity",
        "item_data.quality",
        "item_data.entry_date",
        "item_data.id",
        "entry_date",
        "storage_id",
        "description"
      ]),
      this.filtersActive
    ).then(res => {
      this.paginationHistorial.total = res.data.total;
      this.paginationHistorial.current_page = res.data.current_page;
      this.paginationHistorial.per_page = res.data.per_page;
      this.nuecesHistorial = res.data.data;
      this.isLoading = false;
    });
  }

  @Watch("isFilterToggled")
  restoreItems(val: boolean) {
    if (!val) {
      delete this.filtersActive["item_data.weight*min"];
      delete this.filtersActive["client_id"];
      Object.keys(this.filters).forEach(el => {
        delete this.filtersActive[el + "*min"];
      });
      this.getAllItems();
      this.filters.producer = "";
    }
  }

  @Watch("filters.id")
  setId(val: number) {
    if (val) {
      this.filtersActive["id"] = val;
    } else {
      delete this.filtersActive["id"];
    }
    this.getAllItems();
  }

  @Watch("pagination.current_page")
  changePage(val: number) {
    this.filtersActive["current_page"] = val;
    this.getAllItems();
  }

  @Watch("filters", { deep: true })
  setFilters(val: any) {
    Object.keys(val).forEach(el => {
      delete this.filtersActive[el + "*min"];
      if (val[el] === true && el.includes("item_data")) {
        this.filtersActive[el + "*min"] = 0;
      }
    });
    this.getAllItems();
  }

  @Watch("paginationHistorial.current_page")
  changePageHistorial(val: number) {
    this.filtersActive["current_page"] = val;
    this.loadPast();
  }

  sortItemData(a: any, b: any, isAsc: boolean, type: string) {
    return isAsc
      ? a.item_data[0][type] - b.item_data[0][type]
      : b.item_data[0][type] - a.item_data[0][type];
  }

  getDashboard() {
    this.isLoading = true;
    ItemService.dashboard(this.storage.id).then((response: any) => {
      this.$store.dispatch("ItemModule/setDashboard", response.data);
      this.isLoading = false;
    });
  }

  mounted() {
    this.filtersActive["current_page"] = this.pagination.current_page;
    this.filtersActive["per_page"] = this.pagination.per_page;
    CatalogService.getStackTypes().then(response => {
      this.medidas = response.data;
    });
    this.getAllItems();
    this.getDashboard();
  }
}
