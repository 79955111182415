import http from "@/api/http";
class ItemService {
  create(data: any, fields?: string) {
    return http.post("/item", data, {
      params: {
        select: fields
      }
    });
  }
  update(itemId: number, data: any, fields?: string) {
    return http.patch(`/item/${itemId}`, data, {
      params: {
        select: fields
      }
    });
  }
  getAll(storageId: number, fields?: string, filters?: any) {
    return http.get(`/item/storage/${storageId}`, {
      params: {
        select: fields,
        ...filters
      }
    });
  }
  exportAllExcel(
    storageId: number,
    fields?: string,
    filters?: any,
    structure?: any
  ) {
    return http.post(`/item/storage/${storageId}`, structure, {
      responseType: "blob",
      params: {
        select: fields,
        excel: true,
        ...filters
      }
    });
  }
  getHistorial(storageId: number, fields?: string, filters?: any) {
    return http.get(`/item/storage/${storageId}/historial`, {
      params: {
        select: fields,
        ...filters
      }
    });
  }
  delete(nuezId: number) {
    return http.delete(`/item/${nuezId}`);
  }
  dashboard(storageId: number, filters?: any) {
    return http.get(`/dashboard/${storageId}`, {
      params: {
        ...filters
      }
    });
  }
}

export default new ItemService();
