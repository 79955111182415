import http from "@/api/http";
class ItemDataService {
  create(item_id: number, data: any, fields?: string) {
    return http.post(`/item_data/${item_id}`, data, {
      params: {
        select: fields
      }
    });
  }
  getByItem(item_id: number, fields?: string) {
    return http.get(`/item_data/${item_id}`, {
      params: {
        select: fields
      }
    });
  }
  get(item_data_id: number, fields?: string) {
    return http.get(`/item_data/data/${item_data_id}`, {
      params: {
        select: fields
      }
    });
  }
  update(item_data: any, fields?: string) {
    return http.patch(`/item_data/${item_data.id}`, item_data, {
      params: {
        select: fields
      }
    });
  }
  delete(itemDataId: number) {
    return http.delete(`/item_data/${itemDataId}`);
  }
  getDetail(item_data_id: number, fields?: string) {
    return http.get(`/item_data_detail/${item_data_id}`, {
      params: {
        select: fields
      }
    });
  }
  createDetail(item_data_id: number, data: any, fields?: string) {
    return http.post(`/item_data_detail/${item_data_id}`, data, {
      params: {
        select: fields
      }
    });
  }
  updateDetail(item_data_id: number, data: any, fields?: string) {
    return http.patch(`/item_data_detail/${item_data_id}`, data, {
      params: {
        select: fields
      }
    });
  }
}

export default new ItemDataService();
