




















































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import ClientService from "@/api/clients";
import { mapGetters } from "vuex";

@Component({})
export default class AddPersonaModal extends Vue {
  cliente = {
    name: "",
    last_name: "",
    phone_number: ""
  };

  @Prop(Number) readonly storageId!: number;

  @Prop(Object) client: any;

  @Emit()
  close() {
    return false;
  }

  createCliente() {
    Object.values(this.cliente).forEach(val => {
      if (val == "") {
        this.$buefy.notification.open({
          message: "Los campos no pueden estar vacios",
          type: "is-danger"
        });
        return;
      }
    });
    this.cliente.name = this.cliente.name.toUpperCase();
    this.cliente.last_name = this.cliente.last_name.toUpperCase();
    if (
      Object.keys(this.client).length === 0 &&
      this.client.constructor === Object
    ) {
      ClientService.create({
        ...this.cliente,
        storage_id: this.storageId
      }).then((response: any) => {
        this.$store.dispatch("ClientModule/addClient", response.data);
        this.$buefy.notification.open({
          message: "Se ha creado un nuevo productor",
          type: "is-success"
        });
        this.$emit("close");
      });
    } else {
      ClientService.update(this.client.id, {
        ...this.cliente
      }).then((response: any) => {
        this.$store.dispatch("ClientModule/replaceClient", response.data);
        this.$buefy.notification.open({
          message: "Se ha editado el productor",
          type: "is-success"
        });
        this.$emit("close");
      });
    }
  }

  mounted() {
    if (
      Object.keys(this.client).length === 0 &&
      this.client.constructor === Object
    ) {
      return;
    }
    this.cliente.name = this.client.name;
    this.cliente.last_name = this.client.last_name;
    this.cliente.phone_number = this.client.phone_number;
  }
}
