














































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
@Component({})
export default class NuezCard extends Vue {
  @Prop(Object) readonly nuez: object | any;
  @Prop(Boolean) readonly historial: boolean | undefined;

  @Emit()
  editNuez(nuezId: number): number {
    return nuezId;
  }

  @Emit()
  deleteNuez(nuezId: number): number {
    return nuezId;
  }

  @Emit()
  hideNuez(nuezId: number): number {
    return nuezId;
  }

  @Emit()
  showSamples(nuezId: number): number {
    return nuezId;
  }

  @Emit()
  addSample(nuezId: number): number {
    return nuezId;
  }

  get statusCard() {
    const status = [
      {
        bg: "border-2 border-purple-400",
        label: "Contenedores (Arpillas, etc..)"
      },
      {
        bg: "border-2 border-pink-400",
        label: "Humedad"
      },
      {
        bg: "border-2 border-yellow-400",
        label: "Peso"
      },
      {
        bg: "border-2 border-blue-400",
        label: "Calidad"
      },
      {
        bg: "border-2 border-green-400",
        label: "Precio por punto"
      }
    ];
    if (this.itemGlobal.stacks) {
      status[0].bg = `${status[0].bg} bg-purple-400`;
    }
    if (this.itemGlobal.humidity) {
      status[1].bg = `${status[1].bg} bg-pink-400`;
    }
    if (this.itemGlobal.weight) {
      status[2].bg = `${status[2].bg} bg-yellow-400`;
    }
    if (this.itemGlobal.quality) {
      status[3].bg = `${status[3].bg} bg-blue-400`;
    }
    if (this.nuez.price) {
      status[4].bg = `${status[4].bg} bg-green-400`;
    }
    return status;
  }

  borderStatus(nuez: any): string {
    if (
      this.itemGlobal.stacks &&
      this.itemGlobal.humidity &&
      this.itemGlobal.weight &&
      this.itemGlobal.quality &&
      this.nuez.price
    ) {
      return "border-green-400";
    }
    return "border-red-400";
  }
  get itemGlobal() {
    return this.nuez.item_data.find((n: any) => n.sample == false);
  }
}
