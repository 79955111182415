





























































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import ItemDataService from "@/api/itemdata";
import EditMuestraModal from "@/components/nueces/EditMuestraModal.vue";

@Component({
  components: {
    EditMuestraModal
  }
})
export default class TableMuestra extends Vue {
  columns: any[] = [];
  muestras: any[] = [];
  isUpdating = false;
  itemToUpdate: any = {};

  @Emit()
  close() {
    return false;
  }

  @Prop(Object) readonly nuezSelected: any;
  @Prop(Boolean) readonly historial: boolean | undefined;

  toggleUpdate(itemData: any) {
    this.itemToUpdate = itemData;
    this.isUpdating = true;
  }

  deleteItemData(itemDataId: number) {
    this.$buefy.dialog.confirm({
      message: "¿Estas seguro de borrar la muestra?",
      cancelText: "Cancelar",
      confirmText: "Si, borrar",
      type: "is-danger",
      onConfirm: () => {
        ItemDataService.delete(itemDataId)
          .then(response => {
            const index = this.muestras.findIndex(
              x => x.id === response.data.id
            );
            this.muestras.splice(index, 1);
            this.$buefy.notification.open({
              message: "La muestra se ha eliminado",
              type: "is-success"
            });
          })
          .catch(error => {
            this.$buefy.notification.open({
              message:
                "La muestra no se puede eliminar porque esta ligada a un lote",
              type: "is-warning"
            });
          });
      }
    });
  }

  getItemDataByItem() {
    ItemDataService.getByItem(
      this.nuezSelected.id,
      "entry_date, id, humidity, quality, stacks, weight, lot, number_of_nuts"
    ).then(response => {
      this.muestras = response.data;
    });
  }

  mounted() {
    this.getItemDataByItem();
    this.columns = [
      {
        field: "id",
        label: "ID muestra"
      },
      {
        field: "number_of_nuts",
        label: "Número de piezas",
        numeric: true
      },
      {
        field: "quality",
        label: "Calidad",
        type: "percentage"
      },
      {
        field: "weight",
        label: "Peso (Kg)",
        numeric: true
      },
      {
        field: "stacks",
        label: `# ${this.nuezSelected.stack_type.name}`,
        numeric: true
      },
      {
        field: "humidity",
        label: "Humedad",
        type: "percentage"
      },
      {
        field: "entry_date",
        label: "Fecha de muestreo",
        type: "date"
      }
    ];
  }
}
