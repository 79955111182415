import http from "@/api/http";

class ClientService {
  get(storageId: number, fields?: string) {
    return http.get(`/client/${storageId}`, {
      params: {
        select: fields
      }
    });
  }
  search(storageId: number, query: string, fields?: string) {
    return http.get(`/client/${storageId}/search`, {
      params: {
        client: query,
        select: fields
      }
    });
  }
  create(data: object) {
    return http.post("/client", data);
  }
  update(clientId: number, data: object) {
    return http.patch(`/client/${clientId}`, data);
  }
  delete(clientId: number) {
    return http.delete(`/client/${clientId}`);
  }
}

export default new ClientService();
