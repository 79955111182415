






























































































































import { Component, Emit, Vue, Watch } from "vue-property-decorator";
import AddPersonaModal from "@/components/personas/AddPersonaModal.vue";
import ClientService from "@/api/clients";
import CatalogService from "@/api/catalogs";
import ItemService from "@/api/items";
import { mapGetters } from "vuex";

@Component({
  components: {
    AddPersonaModal
  },
  computed: {
    ...mapGetters("ClientModule", {
      clients: "getClients"
    }),
    ...mapGetters("UserModule", {
      activeStorage: "getStorage"
    })
  }
})
export default class AddModal extends Vue {
  [x: string]: any;
  isAddingPersona = false;
  personasName = "";
  personaSelected = null;
  medidas: any[] = [];
  clients!: any[];
  activeStorage!: any;

  item = {
    entry_date: new Date(),
    description: undefined,
    storage_id: undefined,
    client_id: undefined,
    stack_type_id: 1,
    in_storage: false,
    weight: undefined,
    stacks: undefined,
    humidity: undefined,
    quality: undefined
  };

  @Emit()
  close() {
    return false;
  }

  addItem() {
    if (
      this.item.storage_id == undefined ||
      this.item.client_id == undefined ||
      this.item.stacks == 0 ||
      this.item.stacks === undefined
    ) {
      this.$buefy.notification.open({
        message: `Necesitas tener una sucursal, un cliente seleccionado y el numero de ${
          this.medidas.find(x => x.id == this.item.stack_type_id).name
        }`,
        type: "is-danger"
      });
      return;
    }
    ItemService.create(
      this.item,
      "id, price, stack_type_id, in_storage, client_id, entry_date, item_data.sample, item_data.weight, item_data.stacks, item_data.humidity, item_data.quality, item_data.entry_date, item_data.id, description"
    ).then(response => {
      this.$store.dispatch("ItemModule/addItem", response.data);
      this.$emit("close");
    });
  }

  get clientsName() {
    if (Array.isArray(this.clients)) {
      return this.clients.map((x: any) => {
        return {
          fullname: x.name + " " + x.last_name,
          id: x.id
        };
      });
    }
    return [];
  }
  @Watch("personaSelected")
  setClientId(val: any) {
    this.item.client_id = val.id;
  }

  get filteredDataArray() {
    return this.clientsName.filter(option => {
      return (
        option.fullname
          .toString()
          .toLowerCase()
          .indexOf(this.personasName.toLowerCase()) >= 0
      );
    });
  }

  mounted() {
    CatalogService.getStackTypes().then(response => {
      this.medidas = response.data;
    });
    if (this.activeStorage) {
      this.item.storage_id = this.activeStorage.id;
      ClientService.get(this.activeStorage.id, "name,last_name,id").then(
        response => {
          this.$store.dispatch("ClientModule/setClients", response.data);
        }
      );
    } else {
      this.$buefy.notification.open({
        message: "Necesitas especifiar una sucursal antes de agregar nueces",
        type: "is-danger"
      });
    }
  }
}
